<template>
  <div class="login-page">
    <div class="layer bg" id="login"></div>
    <div class="layer flex-center">
      <!-- 表单部分 -->
      <div class="form-group">
        <el-card>
          <div class="logo-group">
            <i class="el-icon-s-comment"></i>
            <div class="logo_text">微客运营中心管理台</div>
          </div>
          <el-form
            ref="loginForm"
            label-position="top"
            :rules="rules"
            :model="formLogin"
            size="small"
          >
            <el-form-item prop="username">
              <el-input
                type="text"
                v-model="formLogin.username"
                placeholder="请输入用户名称"
              >
                <i slot="prepend" class="el-icon-s-custom"></i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                @keyup.enter.native="submit"
                v-model="formLogin.password"
                placeholder="请输入登录密码"
              >
                <i slot="prepend" class="el-icon-lock"></i>
              </el-input>
            </el-form-item>
            <el-button
              @click="submit"
              size="medium"
              type="primary"
              class="button-login"
            >
              登录
            </el-button>
          </el-form>
        </el-card>
      </div>
    </div>
    <div class="footer-copeyright">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        粤ICP备2021106785号-1
      </a>
    </div>
  </div>
</template>

<script>
import http from '@/services/api/common'
/* eslint-disable */
export default {
  data() {
    return {
      // 表单
      formLogin: {
        username: '',
        password: ''
      },
      // 校验
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  mounted() {},
  methods: {
    /**
     * @description 提交表单
     */
    loginIn(params) {
      http
        .loginIn(params)
        .then((res) => {
          localStorage.setItem('token', res.data.token)
          localStorage.setItem('username', res.data.username)
          this.$router.push('/home')
        })
        .catch((error) => {
          console.log('error', error)
        })
    },
    // 提交登陆信息
    submit() {
      const params = this.formLogin
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginIn(params)
        } else {
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import './style.scss';

.footer-copeyright {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  line-height: 50px;
  text-align: center;
  color: #e2e2e2;
  // background-color: #f8f8f8;
  a {
    color: #fff;
  }
}
</style>
